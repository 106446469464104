import React, { useState, useEffect, useRef } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Particles from "react-particles-js"
import Header from "../components/header"
import Footer from "../components/footer"
import Sponsors from "../components/sponsors"
import firebase from "gatsby-plugin-firebase"
import { Official } from "../components/registration-types"
import Countup from "react-countup"
import { HallOfFame } from "../components/halloffame"

const Column = props => (
  <div className="col-md-4 mb-5">
    <h3 className="text-white">{props.title}</h3>
    <p className="text-white mt-2">{props.body}</p>
    <span className="text-white">
      <Link to={props.to} className="sans">
        {props.linkText}
      </Link>{" "}
      <span className="material-icons">arrow_right_alt </span>
    </span>
  </div>
)
const Hero = () => (
  <section className="container hero bg-dark-main flex-center-col">
    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
      <Particles height="100%" />
    </div>
    <div
      style={{ zIndex: 2, animationDuration: "2000ms" }}
      className="flex-center-col text-center"
    >
      <h1
        style={{ fontWeight: 600 }}
        className="mt-5 mb-3 text-rainbow monospace"
      >
        Thailand Computing Olympiad 2020
      </h1>
      <h4 className="text-white">
        การแข่งขันเขียนโปรแกรม ที่จะทำให้ "โค้ดดิ้ง" ไม่เหมือนเดิมอีกต่อไป<br />
        การแข่งขันจบแล้ว ขอขอบคุณผู้ร่วมแข่งขันทุกคน
      </h4>
      <div
        className="d-flex"
        style={{ flexDirection: "column", alignItems: "center" }}
      >
        <a
          className="p-3 mt-4 btn-lg btn btn-outline-light mt-4 mb-1 no-hover"
          href="https://stats.thaco.tech"
          style={{ borderWidth: 1.5 }}
        >
          ผลการแข่งขัน
        </a >
        <Link
          className="ml-3 mt-4 btn btn-link  text-white"
          to='tasks'
        >
          โจทย์การแข่งขัน<span className='ml-1 material-icons'>navigate_next</span>
        </Link>

      </div>
    </div>
  </section>
)
const IndexPage = () => {
  const [isShowingInteractive, showInteractive] = useState<boolean>(false)
  const interactive = useRef<HTMLElement>()
  const [currentCount, setCurrentCount] = useState<number>(0)
  const [lastCount, setLastCount] = useState<number>(0)
  useEffect(() => {
    if (firebase) {
      const firestore = firebase.firestore()
      return firestore
        .collection("stats")
        .doc("stats")
        .onSnapshot(doc => {
          const { counter } = doc.data()
          if (counter !== currentCount) {
            setLastCount(currentCount)
            setCurrentCount(counter)
          }
        })
    }
  }, [firebase, currentCount])

  useEffect(() => {
    let detectInteractive = () => {
      if (
        typeof interactive.current === "undefined" ||
        interactive.current === null
      )
        return

      let interactivePosition = interactive.current.getBoundingClientRect().top

      if (interactivePosition === 0) return

      if (interactivePosition > window.innerHeight / 1.3) return

      showInteractive(true)

      window.removeEventListener("scroll", detectInteractive)
    }

    window.addEventListener("scroll", detectInteractive, {
      passive: true,
    })

    detectInteractive()
  }, [])

  return (
    <Layout>
      <SEO title="THACO | Thailand Computing Olympiad 2020" name="" />
      <Header page="index" />
      <Hero />
      <section className="container pt-5">
        <div className="row">
          <Column
            title="ต้องการแต่งโจทย์?"
            body="เราพร้อมให้ทุกคนมีส่วนร่วมในการแต่งโจทย์ หากต้องการร่วมงานกับเรา สามารถศึกษากระบวนการแต่งโจทย์และการส่งโจทย์ได้ตามนี้"
            linkText="กระบวนการแต่งโจทย์"
            to="/submission"
          />
          <Column
            title="ห้ามพลาด!"
            body="การแข่งขันจะจัดขึ้นในวันเสาร์ที่ 7 และวันอาทิตย์ที่ 8 ของเดือนพฤศจิกายน พ.ศ.2563 สามารถดูกำหนดการเพิ่มเติมได้ที่นี่"
            linkText="กำหนดการ"
            to="/schedule"
          />
          <Column
            title="รายละเอียดการแข่งขัน"
            body="รูปแบบการแข่งขัน จะเป็นการแข่งขันยาวรอบละ 3 ชั่วโมง จัดขึ้น 2 รอบ โดยมีโจทย์การแข่งขันรอบละ 3 ข้อ สามารถดูรายละเอียดโจทย์แต่ละข้อและขอบเขตเนื้อหาที่ใช้ในการแข่งขันได้ที่นี่"
            linkText="เกี่ยวกับการแข่งขัน"
            to="/register"
          />
        </div>
      </section>
      <section className="container pb-5">
        <hr />
        <h2 className="mt-5 text-white text-center">ผู้ร่วมบริจาค</h2>
        <HallOfFame />
        <div className='text-center text-white'>
          <Link to='donors'>รายชื่อผู้ร่วมมบริจาค</Link> <span className='material-icons'>navigate_next</span>
        </div>
      </section>
      <section className="container text-white" ref={interactive}>
        <hr />
        <div
          className={`${isShowingInteractive ? "interacted" : null}`}
          style={{ opacity: 0 }}
        >
          <div className="pt-5">
            <h2
              style={{
                margin: "auto",
                textAlign: "center",
              }}
            >
              จำนวนผู้สมัครแบบ <Official /> ขณะนี้
            </h2>
          </div>
          <div className="row p-3">
            <div
              className="counter text-center"
              style={{
                margin: "auto",
              }}
            >
              {isShowingInteractive ? (
                <Countup
                  start={lastCount}
                  end={currentCount || 0}
                  duration={3}
                  delay={0}
                  useEasing={true}
                  separator=","
                  suffix=" คน"
                >
                  {({ countUpRef }) => <h1 ref={countUpRef} />}
                </Countup>
              ) : (
                  <h1>0 คน</h1>
                )}
            </div>
          </div>
        </div>
      </section>
      <section
        className="mt-5 mb-5 bg-hero"
        style={{
          paddingTop: 70,
          paddingBottom: 70,
          backgroundImage: `url(${require("../assets/images/bg-yak.jpg")})`,
        }}
      >
        <div className="container">
          <div style={{ maxWidth: 800 }}>
            <p className="text-white mb-0 ">
              พวกเราคือกลุ่มอดีตนักเรียนค่ายโอลิมปิกวิชาการคอมพิวเตอร์ที่มีความมุ่งหมายจะพัฒนาวงการโอลิมปิกวิชาการคอมพิวเตอร์
              สาขาวิชาวิทยาการคำนวณ
              และวิชาการด้านคอมพิวเตอร์ทั่วไปภายในประเทศไทย
              เพื่อให้เหล่าคนรุ่นใหม่ได้มีโอกาสฝึกฝนทักษะวิธีกระบวนการคิด
              การเขียนโปรแกรม การสร้างแบบจำลองโจทย์อย่างสร้างสรรค์
              และการพัฒนาวิธีการในการแก้โจทย์ให้มีประสิทธิภาพมากยิ่งขึ้น
            </p>
            <br />
            <p className="text-white mb-0 ">
              พวกเราคาดหวังว่า นักเรียน ครู อาจารย์
              หรือบุคคลทั่วไปที่สนใจการเขียนโปรแกรมทุกท่าน
              จะได้รับประโยชน์จากการเข้าร่วมการแข่งขันครั้งนี้ ไม่มากก็น้อย
              และเราคาดหวังว่าค่ายโอลิมปิกวิชาการ สอวน. สสวท.
              รวมถึงการแข่งขันโอลิมปิกวิชาการระดับชาติ จะพัฒนาไปยังระดับต่อไป
            </p>
          </div>
        </div>
      </section>

      <section className="container pt-4 pb-4 ">
        <h2 className="text-white text-center">ผู้สนับสนุนหลัก</h2>

        <Sponsors />
        {/*Special thanks to the organizations that make THACO 2020 possible.*/}

      </section>
      <Footer />
    </Layout>
  )
}

export default IndexPage
