import React, { useState, useEffect, useRef } from "react"
import Button from 'react-bootstrap/Button'
import { Link } from 'gatsby'
import axios from 'axios'
import { apiUrl } from "./constants"

interface Item {
  displayName: string
  amount: number
}
export const HallOfFame = () => {

  const [nameList, setNameList] = useState<Array<Item>>(undefined)
  useEffect(() => {
    axios.get(`${apiUrl}/api/donate/halloffame`)
      .then(req => {
        setNameList(req.data)
      }).catch(err => {
        console.log(err)
      })
  }, [])
  /*
  const nameList = [
    { displayName: "นาย สิรวิชญ์ พงศ์นคินทร์", amount: 50 },
    { displayName: "นาย สมชาย สบายดี", amount: 80 },
    { displayName: "นาง สมจิตร ไม่เคยสนใจ", amount: 45 },
    { displayName: "นางสาว อะไร ก็ไม่ทราบ", amount: 20000 },
    { amount: 400 },
  ]
  */
  function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
 
    return (
      <div className='container text-white'>
        <div className='text-center' style={{ maxWidth: 720, margin: 'auto' }}>
          <div className='text-center mb-4'>
            เนื่องจากเราเป็นกลุ่มผู้ไม่แสวงหาผลกำไร และไม่มีค่าใช้จ่ายในการเข้าร่วม เราจึงต้องการเงินทุนช่วยเหลือสำหรับการจัดการดูแลตัวตรวจ การจัดส่งรางวัล และอื่น ๆ
        </div>
          <Button disabled href='/donate' size='lg' variant='outline-light'>ปิดรับบริจาคแล้ว</Button>
        </div>
        <div className="row mt-5 mb-3">
          {!nameList &&
            <div className='w-100 text-center mb-4'>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          }
          {nameList && nameList.map(indv =>
            indv ? (
              <div className="col-md-4 col-12 my-2 text-center">
                <h5>
                  {indv.displayName || (
                    <span style={{ opacity: 0.6 }}>ไม่ประสงค์ออกนาม</span>
                  )}
                </h5>
                <p>
                  <span className="green">{formatNumber(indv.amount ?? 0)}</span> บาท
            </p>
              </div>
            ) : (
                <div className="col-md-4 col-12 my-0" />
              )
          )}
        </div>
      </div>
    )
  }
  export default HallOfFame
